<script setup lang="ts">
import { PopoverRoot, useForwardPropsEmits } from 'radix-vue'
import type { PopoverRootEmits, PopoverRootProps } from 'radix-vue'
import type { EmitFnFormat } from "@/types"

const props = defineProps<PopoverRootProps>()
const emits = defineEmits<EmitFnFormat<PopoverRootEmits>>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <PopoverRoot v-bind="forwarded">
    <slot />
  </PopoverRoot>
</template>
